import * as React from "react"

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 200 200" {...props}>
      <defs>
        <style>{".cls-1{fill:#015bbe;}.cls-2{fill:#fd8401;}"}</style>
      </defs>
      <title>{"workday-logo_200x200_square"}</title>
      <g id="Guidelines">
        <g id="aBQzwa.tif">
          <path
            className="cls-1"
            d="M99.92,109c-1.17,15-6.21,29-9.86,43.37-2.85,11.18-5.92,22.31-8.88,33.46-.74,2.81-2.32,4.12-5.29,4.14q-8.82.06-17.65,0c-2.92,0-3.94-.95-4.82-4.16L39.86,136q-4.78-17.57-9.58-35.13c-.8-2.94.75-5,3.82-5,3.67,0,7.35,0,11,0,3,0,4.36,1.14,5.08,4.11Q57.63,130.61,65,161.27a118.89,118.89,0,0,1,2.33,16.39,2.51,2.51,0,0,0,.3,1.45c.66-.11.72-.65.78-1.13,1.52-12.26,5.24-24,8.36-35.9,3.66-13.88,7.38-27.74,11.06-41.61.86-3.25,2.56-4.61,5.92-4.62,4.43,0,8.86,0,13.28,0,3.31,0,5.22,1.45,6.06,4.56q8.44,31.17,16.87,62.33a131.27,131.27,0,0,1,2.88,16,1.86,1.86,0,0,0,.61,1.51c.33-2.29.67-4.44.95-6.61,1.22-9.63,3.59-19,5.66-28.5Q145,122.57,150,100a4.86,4.86,0,0,1,5.14-4.15c3.67-.08,7.35-.07,11,0,2.8.06,4.11,2,3.34,4.92-1.3,5-2.65,9.88-4,14.83l-17,63.08c-.65,2.42-1.26,4.85-1.94,7.27-.75,2.67-2.34,4-5.14,4-5.94.06-11.87,0-17.81,0-2.59,0-3.66-1-4.42-3.86q-4.58-17.08-9.13-34.18c-2.47-9.26-5-18.5-7.35-27.79a123.61,123.61,0,0,1-2.2-13.83C100.52,109.83,100.68,109.25,99.92,109Z"
          />
          <path
            className="cls-2"
            d="M100.06,10c31.31,1.27,54,15.83,67.58,44.22,1.1,2.29,2.38,4.55,2.38,7.23a8.1,8.1,0,0,1-6.4,7.89c-5.37,1.14-9.4-1-11.51-6-6.49-15.5-17.33-26.55-33.27-32.28a55.1,55.1,0,0,0-27.36-2.52,54,54,0,0,0-35,20.26A54.39,54.39,0,0,0,48,63.48a9.59,9.59,0,0,1-15.74,3.4,7.38,7.38,0,0,1-1.65-8.25C38.77,38.1,52.68,23.13,73.44,15A72.78,72.78,0,0,1,100.06,10Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Svg
