import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      style={{
        enableBackground: "new 0 0 200 200",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <rect
        style={{
          fill: "#95BE47",
        }}
        width={200}
        height={200}
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M114.9,59.9c-0.1,0-1.2,0.4-3.1,0.9c-1.8-5.2-5-10.1-10.7-10.1c-0.2,0-0.3,0-0.5,0 c-1.6-2.1-3.6-3.1-5.3-3.1c-13.2,0-19.5,16.5-21.5,24.9c-5.1,1.6-8.8,2.7-9.2,2.9c-2.9,0.9-3,1-3.3,3.7c-0.3,2-7.8,59.9-7.8,59.9 l58.3,10.9l0,0l4.4-90.2C115.7,59.7,115.1,59.8,114.9,59.9z M100.2,63.9c0,0.2,0,0.4,0,0.6c-3.2,1-6.7,2.1-10.2,3.2 c2-7.6,5.6-11.2,8.9-12.6C99.7,57,100.2,59.9,100.2,63.9z M94.9,51.3c0.6,0,1.1,0.2,1.7,0.6c-4.2,2-8.8,7-10.7,17 c-2.8,0.9-5.5,1.7-8.1,2.5C80.1,63.7,85.5,51.3,94.9,51.3z M97.2,95.9c0,0-3.4-1.8-7.6-1.8c-6.1,0-6.4,3.9-6.4,4.8 c0,5.3,13.8,7.3,13.8,19.7c0,9.8-6.2,16-14.5,16c-10,0-15.1-6.2-15.1-6.2l2.7-8.9c0,0,5.3,4.5,9.7,4.5c2.9,0,4.1-2.3,4.1-4 c0-6.9-11.3-7.2-11.3-18.6c0-9.5,6.9-18.8,20.7-18.8c5.3,0,8,1.5,8,1.5L97.2,95.9z M103.7,63.4c0-0.3,0-0.7,0-1.1 c0-3.3-0.5-5.9-1.2-8c2.9,0.4,4.9,3.7,6.1,7.5C107.1,62.3,105.5,62.8,103.7,63.4z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M134.4,66.8c-0.4,0-8.2-0.6-8.2-0.6s-5.5-5.4-6.1-6c-0.2-0.2-0.5-0.3-0.8-0.4l-4.4,90.2l31.6-6.8 c0,0-11.1-75-11.2-75.5C135.2,67.1,134.8,66.9,134.4,66.8z"
        />
      </g>
    </svg>
  )
}

export default Svg
