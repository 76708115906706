import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#005F9E" width={200} height={200} />
      <g>
        <path
          fill="#FFFFFF"
          d="M114.4,79.9h40.1c1.2,0,2.2-1,2.2-2.2v-14c0-1.2-1-2.2-2.2-2.2h-40.1c-1.2,0-2.2,1-2.2,2.2v14 C112.1,78.9,113.2,79.9,114.4,79.9z"
        />
        <path
          fill="#FFFFFF"
          d="M163.5,120.1h-49.1c-1.2,0-2.2,1-2.2,2.2v14c0,1.2,1,2.2,2.2,2.2h49.1c1.2,0,2.2-1,2.2-2.2v-14 C165.8,121.1,164.7,120.1,163.5,120.1z"
        />
        <path
          fill="#FFFFFF"
          d="M114.4,109.2H146c1.2,0,2.2-1,2.2-2.2V93c0-1.2-1-2.2-2.2-2.2h-31.6c-1.2,0-2.2,1-2.2,2.2v14 C112.1,108.2,113.2,109.2,114.4,109.2z"
        />
        <path
          fill="#FFFFFF"
          d="M85.7,112.1c-0.6-1-0.4-2.6,0.5-3.4c0,0,4.5-4.1,6.5-8c2-3.9,2.9-8,2.9-12.5c0-3.3-0.7-6.5-2-9.7 c-1.3-3.2-3.1-6.1-5.4-8.6c-2.3-2.5-5-4.5-8.2-6.1c-3.2-1.6-8.2-2.1-8.2-2.1c-1.2-0.1-3.2-0.2-4.4-0.2H36.5c-1.2,0-2.2,1-2.2,2.2 v72.6c0,1.2,1,2.2,2.2,2.2h16.7c1.2,0,2.2-1,2.2-2.2v-19.2c0-1.2,1-2.2,2.2-2.2h3.5c1.2,0,2.4,0.6,3.4,1.9l12.3,19.9 c0.6,1,2.2,1.9,3.4,1.9h19.4c1.2,0,1.7-0.9,1.1-1.9L85.7,112.1z M72.4,94.2c-1.6,1.9-4.6,2.2-5.9,2.2h-8.9c-1.2,0-2.2-1-2.2-2.2 v-12c0-1.2,1-2.2,2.2-2.2H66c1.2,0,4.4,0.3,6.1,2.1c1.3,1.4,2,3.5,2,6.1C74.1,90.8,73.6,92.8,72.4,94.2z"
        />
      </g>
    </svg>
  )
}

export default Svg
