import * as React from "react"

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 240 90" {...props}>
      <defs>
        <style>{".cls-1{fill:#0296ce;}.cls-2{fill:#8ccd16;}"}</style>
      </defs>
      <title>{"saasoptics-logo_240x90_web"}</title>
      <g id="Guidelines">
        <g id="rmDHYi.tif">
          <path
            className="cls-1"
            d="M39.35,61.23c-.69-.2-1.42-.27-2.1-.52a10.57,10.57,0,0,1-3.13-18.38,10.74,10.74,0,0,1,4.77-2.09c.19,0,.33,0,.37-.3a12.33,12.33,0,0,1,3-6.58A12.59,12.59,0,0,1,53.2,28.82a12.77,12.77,0,0,1,11,7.71c.19.4.31.47.73.31A12.57,12.57,0,0,1,81.79,46,11.8,11.8,0,0,1,72.94,59.9a11.37,11.37,0,0,1-8.21-1.2c.2-.11.35,0,.51.09a10.71,10.71,0,0,0,12.2-2.72,10,10,0,0,0-2.9-15.69,9.42,9.42,0,0,0-9,.32,21.48,21.48,0,0,0-3.76,2.59c-.09.08-.16.21-.33.22a15.28,15.28,0,0,0,.11-2.13,9.32,9.32,0,0,0-18.46-1.27,8.58,8.58,0,0,0,0,3.31c.06.31,0,.35-.31.36a20.11,20.11,0,0,0-3.2.11,6.77,6.77,0,0,0,.38,13.41,12.29,12.29,0,0,0,1.49.07q5.82,0,11.64,0a3,3,0,0,0,2.86-1.8A2.19,2.19,0,0,0,55.23,53a9.65,9.65,0,0,0-3.32-1.72,14,14,0,0,1-3.49-1.46,5.56,5.56,0,0,1-2.57-4.62,7.83,7.83,0,0,1,.22-2.41,5.14,5.14,0,0,1,4-3.87,15.09,15.09,0,0,1,8.55.34.42.42,0,0,1,.35.48c0,.9,0,1.8,0,2.69,0,.29-.06.33-.34.25a15.6,15.6,0,0,0-5.85-.43c-1.91.19-2.5,1.55-2.38,3.07a1.72,1.72,0,0,0,.78,1.12,9.43,9.43,0,0,0,2.42,1.35c.8.33,1.62.62,2.43.94a7.56,7.56,0,0,1,3.47,2.53,6.41,6.41,0,0,1-.56,7.95A7.61,7.61,0,0,1,54.76,61a15.66,15.66,0,0,1-3,.19c-.15,0-.32-.07-.45.07Z"
          />
          <path
            className="cls-2"
            d="M210,45.26c-.56,0-.56,0-.56-.51s0-1.14,0-1.71c0,0,0,0-.06-.09a2.89,2.89,0,0,0-.31.91c-.11.38-.23.76-.31,1.15-.05.22-.15.26-.35.27s-.27-.09-.31-.26c-.17-.69-.36-1.37-.58-2a4,4,0,0,0-.08,1.14q0,.41,0,.81c0,.24,0,.35-.32.35s-.29-.13-.28-.34V42.17c0-.15-.08-.41.2-.36s.65-.23.77.27c.18.74.4,1.48.63,2.33.21-.8.42-1.49.58-2.19.08-.35.23-.48.58-.43a2,2,0,0,0,.42,0Z"
          />
          <path
            className="cls-1"
            d="M134.14,57.73a16.43,16.43,0,0,1-4.48-.54c-.35-.1-.52-.24-.49-.64a16.24,16.24,0,0,0,0-1.71c0-.3,0-.37.37-.29a15,15,0,0,0,5.94.18,2,2,0,0,0,1.77-1.85,1.71,1.71,0,0,0-.82-1.59A8.93,8.93,0,0,0,133.55,50a6.24,6.24,0,0,1-3-1.7,4.66,4.66,0,0,1,2.64-7.66,11.68,11.68,0,0,1,6,.36c.28.09.39.21.38.51,0,.62,0,1.25,0,1.88,0,.26-.06.29-.32.22a11.6,11.6,0,0,0-4.58-.28,1.91,1.91,0,0,0-1.69,2.23,1.37,1.37,0,0,0,.62.93,10.41,10.41,0,0,0,2.93,1.45,8.4,8.4,0,0,1,2.67,1.39,4.77,4.77,0,0,1,0,7.17,6.72,6.72,0,0,1-3.43,1.17A10.71,10.71,0,0,1,134.14,57.73Z"
          />
          <path
            className="cls-1"
            d="M93.84,57.72a14.9,14.9,0,0,1-4.3-.56c-.27-.09-.39-.19-.38-.5,0-.61,0-1.22,0-1.84,0-.28,0-.34.35-.27a14.85,14.85,0,0,0,5.9.18,2,2,0,0,0,1.82-1.82,1.7,1.7,0,0,0-.7-1.52,7.41,7.41,0,0,0-2.3-1.19,19,19,0,0,1-2.32-.86,4.86,4.86,0,0,1-1.23-7.57,5.07,5.07,0,0,1,3-1.27,12.91,12.91,0,0,1,5.21.38c.44.13.64.3.6.78a11.5,11.5,0,0,0,0,1.66c0,.31-.08.32-.34.25a11.58,11.58,0,0,0-4.54-.28,1.93,1.93,0,0,0-1.7,2.26,1.06,1.06,0,0,0,.36.66A5.09,5.09,0,0,0,95,47.3c.78.34,1.59.62,2.38,1a4.8,4.8,0,0,1,3.18,3.31,4.56,4.56,0,0,1-1.14,4.66,5.78,5.78,0,0,1-3.18,1.34A14.21,14.21,0,0,1,93.84,57.72Z"
          />
          <path
            className="cls-1"
            d="M113.83,52.7v4.66c0,.22,0,.37-.31.36-2.16,0-4.34.1-6.5-.08a4.82,4.82,0,0,1-3.21-1.32,3.57,3.57,0,0,1,.57-5.28A7.64,7.64,0,0,1,107.82,50c.75-.08,1.5-.12,2.26-.15.21,0,.3-.07.29-.3a5.75,5.75,0,0,0-.11-1.31A1.6,1.6,0,0,0,108.73,47a12,12,0,0,0-4.76.55c-.38.11-.43,0-.42-.32,0-.68,0-1.37,0-2a.37.37,0,0,1,.28-.44,11.83,11.83,0,0,1,7.11-.27,3.85,3.85,0,0,1,2.82,3.63C113.91,49.7,113.78,51.35,113.83,52.7Zm-3.46.76c0-.37,0-.74,0-1.11S110.29,52,110,52a14.61,14.61,0,0,0-1.87.18,3.19,3.19,0,0,0-1.27.46,1.15,1.15,0,0,0-.15,1.77,1.61,1.61,0,0,0,.92.41,20.49,20.49,0,0,0,2.48.07c.22,0,.25-.11.25-.29C110.36,54.22,110.37,53.84,110.37,53.46Z"
          />
          <path
            className="cls-1"
            d="M127,52.88c0,1.49,0,3,0,4.48,0,.22,0,.37-.31.36-2.17,0-4.34.09-6.5-.08a5.87,5.87,0,0,1-2.66-.85,3.55,3.55,0,0,1,.6-6.08,11.45,11.45,0,0,1,3.69-.84l1.49-.07c.14,0,.25,0,.25-.19a5,5,0,0,0-.13-1.49A1.61,1.61,0,0,0,121.88,47a11.81,11.81,0,0,0-4.76.55c-.37.11-.46,0-.45-.33q0-1,0-2a.44.44,0,0,1,.35-.49,12,12,0,0,1,6.86-.31,3.8,3.8,0,0,1,3,3.36,8.17,8.17,0,0,1,.1,1.4Zm-3.44.65c0-.48,0-.86,0-1.25,0-.23-.07-.29-.29-.27-.48.05-1,0-1.45.1a3.83,3.83,0,0,0-1.77.55,1.11,1.11,0,0,0-.14,1.76,1.71,1.71,0,0,0,.88.41,5.73,5.73,0,0,0,1.31.06c.47,0,1.09.2,1.36-.07S123.47,53.91,123.52,53.53Z"
          />
          <path
            className="cls-2"
            d="M142.85,50.71a26.86,26.86,0,0,1,.09-3.11,4.08,4.08,0,0,1,4-3.78,8.75,8.75,0,0,1,2.93.15A3.76,3.76,0,0,1,153,47.18a24.37,24.37,0,0,1,0,7,3.92,3.92,0,0,1-3.77,3.48A8.52,8.52,0,0,1,146,57.5,4,4,0,0,1,143,54C142.79,52.93,142.9,51.82,142.85,50.71Zm2.77,0c0,.91-.05,1.82.07,2.72a2.07,2.07,0,0,0,2.32,2,2.1,2.1,0,0,0,2.3-2,37.93,37.93,0,0,0,0-5.33,2.33,2.33,0,0,0-4.61,0A26.77,26.77,0,0,0,145.62,50.73Z"
          />
          <path
            className="cls-2"
            d="M155.19,50.73c0-2.13,0-4.27,0-6.4,0-.34.06-.47.43-.46,1.74,0,3.48,0,5.22,0a5.24,5.24,0,0,1,2.16.49,3.11,3.11,0,0,1,1.88,2.46,8.81,8.81,0,0,1,0,2.76,3.16,3.16,0,0,1-2,2.56,6.5,6.5,0,0,1-2.46.45c-.66,0-1.32,0-2,0-.42,0-.49.13-.49.5,0,1.37,0,2.73,0,4.1,0,.33-.07.45-.42.43-.63,0-1.26,0-1.88,0-.37,0-.49-.07-.48-.46C155.2,55,155.19,52.88,155.19,50.73ZM158,48.22c0,.61,0,1.22,0,1.83,0,.2,0,.33.27.32.65,0,1.31,0,2,0,1.36-.09,1.94-.66,2-2,0-1-.24-2.05-1.8-2.17-.71-.06-1.42,0-2.14-.05-.23,0-.28.1-.28.31C158,47,158,47.62,158,48.22Z"
          />
          <path
            className="cls-2"
            d="M198,57.73a13.91,13.91,0,0,1-3.73-.43c-.27-.08-.4-.2-.38-.51a9.11,9.11,0,0,0,0-1.28c0-.34.08-.4.42-.32a12.38,12.38,0,0,0,4.54.14,1.6,1.6,0,0,0,1.54-1.5,1.39,1.39,0,0,0-.74-1.34,7.34,7.34,0,0,0-2.24-1,5.58,5.58,0,0,1-2-1,3.75,3.75,0,0,1-1.15-4,3.23,3.23,0,0,1,2.85-2.53,9.85,9.85,0,0,1,4.73.26.42.42,0,0,1,.37.51,10.93,10.93,0,0,0,0,1.28c0,.31,0,.41-.39.32a8.73,8.73,0,0,0-3.56-.19,1.55,1.55,0,0,0-1.36,1.72,1.16,1.16,0,0,0,.55.84,8.52,8.52,0,0,0,2.24,1.11,9.6,9.6,0,0,1,1.64.72,3.79,3.79,0,0,1,1.84,4,3.43,3.43,0,0,1-2.91,3A9.55,9.55,0,0,1,198,57.73Z"
          />
          <path
            className="cls-2"
            d="M183.26,50.74a30.35,30.35,0,0,1,.09-3.17,3.93,3.93,0,0,1,3.74-3.73,11.79,11.79,0,0,1,4.5.32c.35.09.53.23.49.64a11.61,11.61,0,0,0,0,1.32c0,.2,0,.27-.27.21a11.52,11.52,0,0,0-3.7-.28A1.88,1.88,0,0,0,186.21,48a42.83,42.83,0,0,0,0,5.55,1.91,1.91,0,0,0,2.16,1.91,17.62,17.62,0,0,0,3.32-.32c.28,0,.39,0,.37.29,0,.48,0,1,0,1.45a.34.34,0,0,1-.28.4,10.9,10.9,0,0,1-5.41.25,3.84,3.84,0,0,1-3-3.42A31.33,31.33,0,0,1,183.26,50.74Z"
          />
          <path
            className="cls-2"
            d="M170.27,51.87c0-1.78,0-3.55,0-5.33,0-.35-.1-.43-.43-.42-.91,0-1.83,0-2.74,0-.28,0-.37-.07-.36-.35,0-.53,0-1.06,0-1.58,0-.23.05-.33.31-.33q4.58,0,9.15,0c.29,0,.34.11.33.35,0,.53,0,1.06,0,1.58,0,.27-.09.34-.34.33-.9,0-1.8,0-2.7,0-.39,0-.46.12-.46.48,0,3.51,0,7,0,10.54,0,.39-.08.52-.49.5q-1,0-1.92,0c-.31,0-.38-.1-.38-.39C170.28,55.46,170.27,53.67,170.27,51.87Z"
          />
          <path
            className="cls-2"
            d="M181.23,50.74c0,2.13,0,4.27,0,6.4,0,.43-.12.52-.52.5-.64,0-1.29,0-1.93,0-.26,0-.34-.08-.34-.34q0-6.55,0-13.1c0-.29.11-.33.35-.32.69,0,1.37,0,2.06,0,.3,0,.38.08.38.38C181.22,46.41,181.23,48.58,181.23,50.74Z"
          />
          <path
            className="cls-2"
            d="M67.78,54.74A5.61,5.61,0,0,0,71,55a5,5,0,0,0,2.67-8.28,4.59,4.59,0,0,0-7.13.48c-.18.26-.29.32-.55.11a12.72,12.72,0,0,0-1.2-.88c-.23-.15-.23-.25-.06-.46a6.18,6.18,0,0,1,6.52-2.26,5.75,5.75,0,0,1,4.65,5A5.75,5.75,0,0,1,68.09,55,.54.54,0,0,1,67.78,54.74Z"
          />
          <path
            className="cls-2"
            d="M204.59,43.77a8.85,8.85,0,0,1,0-1c0-.4-.11-.51-.49-.48-.17,0-.47.16-.45-.24,0-.16.06-.23.22-.23h2c.17,0,.26.05.26.24s-.08.23-.25.24c-.67,0-.67,0-.67.72s0,1.3,0,2c0,.22,0,.33-.3.33s-.32-.08-.31-.32C204.6,44.56,204.59,44.17,204.59,43.77Z"
          />
          <polygon className="cls-2" points="67.65 54.72 67.64 54.69 67.67 54.69 67.65 54.72" />
        </g>
      </g>
    </svg>
  )
}

export default Svg
