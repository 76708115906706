import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#FEC540" width={200} height={200} />
      <g>
        <path
          fill="#00698F"
          d="M54,87.5C98.8,67.8,135.7,64,160.6,71.8c19.4,6.1,16.2,18.1,8.3,26c-23.4,23.6-64.1,28.3-67.1,28.6 c-3.9,0.4-2.6-0.8-1.4-1.1c16.5-3.9,42.4-8.1,58.2-25.3C177.9,78.9,132.9,62,56.8,88.5C37.1,95.4,48.8,89.7,54,87.5"
        />
        <path
          fill="#00698F"
          d="M157.6,91.6c0.4,0.5-0.9,3.1-4,5.9c-10.6,9.3-45.7,34.5-117.1,17.9c-2.4-0.6-3.1-2,0.7-1.6 c23.6,2.8,78.4,7.5,114.5-17.9C156.2,92.7,157.1,90.9,157.6,91.6"
        />
        <path
          fill="#00698F"
          d="M72.5,127.2c1.2,1.8,2.9,3.5,7.6,4c2.6,0.3,4.4,0.4,9.1,0.7c1.9,0.1,2.8-0.8,3.4-1.5 c1.1-1.3,1.5-1.9,2.3-3.3c0.3-0.6,0.4-0.9-0.6-0.7c-4.2,0.9-13.7,1.2-21.3,0.1C72.3,126.4,72,126.4,72.5,127.2"
        />
        <path
          fill="#00698F"
          d="M48.4,100.8c-0.1,1-0.5,2.5-2.1,2.8c-1.8,0.4-14.2,1.7-19.7,1.7c-2.5,0-1.7-0.3,0-0.7 c1.7-0.4,19.9-4.5,20.9-4.7C48.5,99.7,48.5,99.9,48.4,100.8"
        />
      </g>
    </svg>
  )
}

export default Svg
