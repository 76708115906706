import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g id="logo">
          <g id="logo_1_">
            <g>
              <ellipse fill="#2F84ED" cx={100} cy={100} rx={85} ry={85} />
            </g>
          </g>
        </g>
        <g>
          <polygon
            fill="#FFFFFF"
            points="52.5,67.1 62.5,67.1 70.2,80.8 77.7,67.1 87.2,67.1 74.6,89 74.6,100.7 65.3,100.7 65.3,89.3  "
          />
          <path
            fill="#FFFFFF"
            d="M84.3,84c0-10.2,8.5-17.2,18.2-17.2s18.2,7.2,18.2,17.2c0,10.2-8.5,17.4-18.2,17.4 C92.8,101.2,84.3,94.3,84.3,84 M111.3,84c0-5.2-3.3-9.5-9-9.5c-5.6,0-9,4.2-9,9.5s3.3,9.5,9,9.5C108.2,93.5,111.3,89.3,111.3,84"
          />
          <polygon
            fill="#FFFFFF"
            points="129.3,74.6 120.3,74.6 120.3,67.1 147.5,67.1 147.5,74.6 138.5,74.6 138.5,100.7 129.3,100.7  "
          />
          <path
            fill="#FFFFFF"
            d="M64.3,137.6h-9V104h12.8c9.2,0,14.4,5.7,14.4,13.2s-5.1,13.2-14.4,13.2h-3.9V137.6z M67.6,122.7 c4.1,0,5.6-2.2,5.6-5.5c0-3.2-1.5-5.7-5.6-5.7h-3.3v11.2H67.6z"
          />
          <path
            fill="#FFFFFF"
            d="M84.1,120.9c0-10.2,8.5-17.2,18.2-17.2s18.2,7.2,18.2,17.2c0,10.2-8.5,17.4-18.2,17.4 C92.6,138.1,84.1,131.2,84.1,120.9 M111.3,120.9c0-5.2-3.3-9.5-9-9.5c-5.6,0-9,4.2-9,9.5c0,5.2,3.3,9.5,9,9.5 S111.3,126.2,111.3,120.9"
          />
          <path
            fill="#FFFFFF"
            d="M120.5,133.4c0-3,2.3-5,4.9-5s4.9,2,4.9,5c0,3-2.3,5-4.9,5C122.9,138.1,120.5,136.1,120.5,133.4"
          />
        </g>
      </g>
    </svg>
  )
}

export default Svg
